const Home = () => {
    return (
        <div>
            <h1>Welcome to the Home Page</h1>
            <h1>Test PB</h1>

        </div>
    );
};

export default Home;
