const About = () => {
    return (
        <div>
            <h1>Welcome to the About Page</h1>
            <h1>Test</h1>
        </div>
    );
};

export default About;
